import { gql } from "graphql-tag";

export const meGQL = gql`
  query Me {
    me {
      avatar
      email
      idpName
      pushSubscription
      xp
      id
      idpId
      username
      language
      metadata {
        desiredStreamResolution
        hasReceivedWelcomeCoins
        hasSeenWelcomePopup
        lastSeenTransactionId
        onboarding
      }
    }
  }
`;

export const coinWalletsGQL = gql`
  query Me {
    me {
      coinWallets {
        balance
        userId
        walletType
        itemType
      }
    }
  }
`;

export const userTransactionsGQL = gql`
  query User($id: UUID!, $coinTypes: [CoinType!], $limit: Int!, $offset: Int!) {
    user(id: $id) {
      transactions(
        input: {
          coinTypes: $coinTypes
          page: { limit: $limit, offset: $offset }
        }
      ) {
        total
        items {
          amount
          coinType
          date
          expiryDate
          id
          referenceId
          type
          game {
            category
            data
            description
            featured
            hasBattle
            hasSolo
            hasTournament
            id
            rotationMode
            status
            title
            type
          }
        }
      }
    }
  }
`;

export const getConfigGQL = gql`
  query Config {
    config {
      freeCoins {
        amount
      }
      prizePools {
        battle {
          coinType
          coinsLoser
          coinsWinner
        }
        challenge {
          coinsCompletion
          coinType
          coinsFailure
          coinsFirstTimeCompletion
        }
      }
      tierLevels {
        amount
        levelId
      }
    }
  }
`;
export const getUserGQL = gql`
  query User($id: UUID!) {
    user(id: $id) {
      allTimeRank
    }
  }
`;
