import {
  PayloadAction,
  createAsyncThunk,
  createAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AppDispatch, AppThunk, RootState } from "../store";
import { GlobalLeaderboardsMd } from "../models/globalLeaderboards";
import { setNotificationError } from "./alert";
import {
  LeaderboardType,
  LIST_TAB_LEADERBOARDS,
  TimeLine,
} from "../constants/leaderboards";
import { getGlobalLeaderboards } from "../graphql/resolvers/queries/globalLeaderboard";
import {
  Activity,
  Leaderboard,
  LeaderboardActivityData,
  LeaderboardUserNotificationData,
  UserLeaderboardActivityData,
} from "../graphql/graphql";
import { GlobalLeaderboardItem } from "../legacyGraphql/graphql";
import { Time } from "../models/time";

export interface IGlobalLeaderboards {
  allTime: Leaderboard | null;
  monthly: Leaderboard | null;
  weekly: Leaderboard | null;
}

interface IGlobalLeaderboard {
  globalLeaderboards: IGlobalLeaderboards;
  historyGlobalLeaderboard: Leaderboard | GlobalLeaderboardItem | undefined;
  tournamentLeaderboard: Leaderboard | undefined;
  activeTab: string;
}

const defaultLeaderboard: IGlobalLeaderboards = {
  allTime: null,
  monthly: null,
  weekly: null,
};

const initialState = {
  globalLeaderboards: defaultLeaderboard,
  historyGlobalLeaderboard: undefined,
  tournamentLeaderboard: undefined,
  activeTab: LIST_TAB_LEADERBOARDS[0],
} as IGlobalLeaderboard;

export const resetGlobalLeaderboardState = createAction(
  "globalLeaderboard/resetState",
);

export const globalLeaderboardSlice = createSlice({
  name: "globalLeaderboard",
  initialState: initialState,
  reducers: {
    updateGlobalLeaderboards: (
      state,
      action: PayloadAction<{
        globalLeaderboards: IGlobalLeaderboards;
      }>,
    ) => {
      state.globalLeaderboards = action.payload.globalLeaderboards;
    },
    updateHistoryGlobalLeaderboard: (
      state,
      action: PayloadAction<{
        historyGlobalLeaderboard:
          | Leaderboard
          | GlobalLeaderboardItem
          | undefined;
      }>,
    ) => {
      state.historyGlobalLeaderboard = action.payload.historyGlobalLeaderboard;
    },
    updateTournamentLeaderboard: (
      state,
      action: PayloadAction<{
        tournamentLeaderboard: Leaderboard | undefined;
      }>,
    ) => {
      state.tournamentLeaderboard = action.payload.tournamentLeaderboard;
    },

    updateActiveTab: (state, action: PayloadAction<{ activeTab: string }>) => {
      state.activeTab = action.payload.activeTab;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalLeaderboardProfile.rejected, (state) => {
        state.historyGlobalLeaderboard = undefined;
      })
      .addCase(getGlobalLeaderboardProfile.fulfilled, (state, action) => {
        state.historyGlobalLeaderboard = action.payload;
      })
      .addCase(resetGlobalLeaderboardState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateGlobalLeaderboards,
  updateActiveTab,
  updateHistoryGlobalLeaderboard,
  updateTournamentLeaderboard,
} = globalLeaderboardSlice.actions;

export const fetchGlobalLeaderboards =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const state = getState();
      const globalLeaderboardType = GlobalLeaderboardsMd.globalLeaderboardType(
        state.globalLeaderboard.activeTab,
      );
      const res = await getGlobalLeaderboards({ globalLeaderboardType });
      if (res.items) {
        dispatch(
          updateGlobalLeaderboards({
            globalLeaderboards: {
              ...state.globalLeaderboard.globalLeaderboards,
              [globalLeaderboardType]: res,
            },
          }),
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e?.message);
      }
      dispatch(
        updateGlobalLeaderboards({ globalLeaderboards: defaultLeaderboard }),
      );
    }
  };

export const getGlobalLeaderboardProfile = createAsyncThunk(
  "globalLeaderboard/getGlobalLeaderboardProfile",
  async (
    lbRequestData: {
      lbActivity?: Activity;
      lbNotificationData?: LeaderboardUserNotificationData;
    },
    { dispatch, signal },
  ) => {
    try {
      const { lbActivity, lbNotificationData } = lbRequestData;
      const isNewType =
        lbActivity?.activityType === "user_leaderboard_ended" ||
        (lbNotificationData as LeaderboardUserNotificationData)?.leaderboardId;

      const globalLeaderboardInput = isNewType
        ? {
            leaderboardId:
              (lbActivity?.data as UserLeaderboardActivityData).leaderboardId ??
              lbNotificationData?.leaderboardId,
          }
        : {
            leaderboardType:
              (lbActivity?.data as LeaderboardActivityData)?.timeFrame ??
              lbNotificationData?.timeFrame,
            endDate: Time.convertTimestampToFormattedDate(
              lbActivity
                ? (lbActivity?.data as LeaderboardActivityData).endDate
                : lbNotificationData?.endDate,
            ),
          };
      const historyGlobalLeaderboard = isNewType
        ? {
            ...(await getGlobalLeaderboards(globalLeaderboardInput)),
            rank:
              (lbActivity?.data as UserLeaderboardActivityData)
                ?.leaderboardRank - 1,
            score: (lbActivity?.data as UserLeaderboardActivityData)?.score,
          }
        : await GlobalLeaderboardsMd.getGlobalLeaderboardProfile(
            globalLeaderboardInput.endDate ?? "",
            globalLeaderboardInput.leaderboardType || "",
            {
              fetchPolicy: "network-only",
              context: {
                fetchOptions: {
                  signal,
                },
              },
            },
          );

      return historyGlobalLeaderboard;
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(setNotificationError(e.message));
      }
    }
  },
);

const selectGlobalLeaderboardData = (state: RootState) => ({
  globalLeaderboards: state.globalLeaderboard.globalLeaderboards,
  historyGlobalLeaderboard: state.globalLeaderboard.historyGlobalLeaderboard,
  tournamentLeaderboard: state.globalLeaderboard.tournamentLeaderboard,
  activeTab: state.globalLeaderboard.activeTab,
  me: state.user.me,
});

export const selectCurrentLeaderboard = createSelector(
  [
    selectGlobalLeaderboardData,
    (state: RootState, leaderboardType?: LeaderboardType) => leaderboardType,
  ],
  (selectGlobalLeaderboardData, leaderboardType) => {
    const {
      globalLeaderboards,
      historyGlobalLeaderboard,
      tournamentLeaderboard,
      activeTab,
      me,
    } = selectGlobalLeaderboardData;

    const currentLb = GlobalLeaderboardsMd.getCurrentLeaderboard(
      activeTab,
      globalLeaderboards,
      historyGlobalLeaderboard,
      tournamentLeaderboard,
      leaderboardType,
    );

    const isOldLb =
      historyGlobalLeaderboard?.__typename === "GlobalLeaderboardItem";

    return {
      ...currentLb,
      leaderboard: [
        ...(GlobalLeaderboardsMd.getTop3Lb(
          isOldLb
            ? (currentLb as GlobalLeaderboardItem)?.leaderboard
            : (currentLb as Leaderboard)?.items,
        ) ?? []),
        ...(GlobalLeaderboardsMd.getListOutTop3Lb(me, currentLb) ?? []),
      ],
    };
  },
);

export const selectIsShowTimeLeft = createSelector(
  [
    selectGlobalLeaderboardData,
    (state: RootState, leaderboardType?: LeaderboardType) => leaderboardType,
  ],
  (selectGlobalLeaderboardData, leaderboardType) => {
    const { activeTab } = selectGlobalLeaderboardData;
    return (
      (leaderboardType === LeaderboardType.GlobalLeaderboard &&
        activeTab !== TimeLine.AllTime) ||
      leaderboardType === LeaderboardType.TournamentOnGoing
    );
  },
);

export default globalLeaderboardSlice.reducer;
