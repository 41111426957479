import { TabActivities } from "../../constants/activities";
import { groupBy, map } from "lodash-es";
import { Time } from "../time";
import {
  Activity,
  ActivityCategory,
  ActivityCategoryFilterInput,
  ActivityType,
  BattleActivityData,
  BattleType,
  BattleUserNotificationData,
  User,
} from "../../graphql/graphql";
import { getFormatDateLanguage } from "../users/users";
import { TFunction } from "i18next/typescript/t";
import {
  CoinBattleType,
  PlayMatch,
  PublicUser,
} from "../../legacyGraphql/graphql";

export interface IActivityCategoryFilters {
  battleType?: BattleType;
  battleTypes?: BattleType[];
  activityType?: ActivityType;
}

export interface IActivityCategoryWithFilters {
  filters: IActivityCategoryFilters;
  name: ActivityCategory;
}

export const isBattleActivity = (activity: Activity) => {
  const battleActivity = activity.data as BattleActivityData;
  return (
    battleActivity?.battleType === BattleType.Battle ||
    battleActivity?.battleType === BattleType.HouseBattle
  );
};

export const isChallengeActivity = (activity: Activity) => {
  const battleActivity = activity.data as BattleActivityData;
  return battleActivity?.battleType === BattleType.Solo;
};

export const isTournamentActivity = (activity?: Activity) => {
  const battleActivity = activity?.data as BattleActivityData;
  return battleActivity?.battleType === BattleType.Tournament;
};

export const isLeaderboardActivity = (activity: Activity) => {
  return ["user_leaderboard_ended", "leaderboard_ended"].includes(
    activity.activityType,
  );
};

export const getOpponent = (activity: Activity) => {
  const battleActivity = activity.data as BattleActivityData;
  return battleActivity?.opponentUsers?.[0];
};
export const getActivityGameTitle = (activity: Activity) => {
  const battleActivity = activity.data as BattleActivityData;
  return battleActivity.game.title;
};

export const isUnranked = (activity: Activity) => {
  const battleActivity = activity.data as BattleActivityData;
  return !battleActivity?.rank;
};

export const hasWinner = (activity: Activity) => {
  const battleActivity = activity.data as BattleActivityData;
  return !!battleActivity?.winner;
};

export const groupActivitiesByStartedDate = (
  activities: Activity[],
): Record<string, Activity[]> | undefined => {
  if (!activities || !activities.length) return;
  const arenaActivities = map(activities, (activity) => {
    return {
      ...activity,
      displayDate: Time.formatDate(
        activity.createdAt,
        "yyyy-mm-dd",
        activity.activityType === ActivityType.BattleInviteSent ||
          activity.activityType === ActivityType.BattlePlayed,
      ),
    };
  });

  const priorityActivities = arenaActivities
    .filter((activity) => activity.displayDate === "Recent")
    .concat(
      arenaActivities.filter((activity) => activity.displayDate !== "Recent"),
    );

  return groupBy(priorityActivities, "displayDate");
};

export const subscriptionFriendBattleToActivity = (
  battleRequest: PlayMatch,
): Activity => {
  const fullBattleInfo = battleRequest?.fullBattleInfo;
  const opponent = battleRequest.players.find(
    (player) => player.id === battleRequest?.fullBattleInfo?.hostUserId,
  );

  return {
    __typename: "BattleInviteActivity",
    activityType: ActivityType.BattleInvited,
    battleId: fullBattleInfo.matchId,
    battleType: CoinBattleType.Battle,
    createdAt: new Date().toISOString(),
    data: JSON.stringify({
      userId: "",
      hostUserId: opponent?.id,
      momentId: fullBattleInfo.momentId,
      gameId: fullBattleInfo.gameId,
      battleId: fullBattleInfo.matchId,
      gameTitle: "",
      battleType: CoinBattleType.Battle,
    }),
    gameId: fullBattleInfo.gameId,
    gameTitle: "",
    momentId: fullBattleInfo.momentId,
    opponentUsers: [
      {
        __typename: "PublicUser",
        avatar: opponent?.avatar || "",
        banner: "",
        id: opponent?.id || "",
        username: opponent?.username || "",
        xp: opponent?.xp || 0,
        cheatSessions: 0,
      } as PublicUser,
    ],
  };
};
export const subscriptionNotificationToActivity = (
  battleRequest: BattleUserNotificationData,
): Activity => {
  return {
    __typename: "BattleInviteActivity",
    activityType: ActivityType.BattleInvited,
    battleId: battleRequest.matchId,
    battleType: BattleType.Battle,
    createdAt: new Date().toISOString(),
    data: JSON.stringify({
      userId: "",
      hostUserId: battleRequest.host.id,
      gameId: battleRequest.gameId,
      battleId: battleRequest.matchId,
      gameTitle: "",
      battleType: BattleType.Battle,
    }),
    gameId: battleRequest.gameId,
    gameTitle: "",
    momentId: "",
    opponentUsers: [
      {
        __typename: "PublicUser",
        avatar: battleRequest.host?.avatar || "",
        id: battleRequest.host?.id || "",
        username: battleRequest.host?.username || "",
        xp: battleRequest.host?.xp || 0,
        followStatus: battleRequest.host.followStatus,
        lastCheckin: battleRequest.host.lastCheckin,
      } as PublicUser,
    ],
  };
};

export const categoryWithFilters = (type: string) => {
  const activityCategoryfilters = {} as ActivityCategoryFilterInput;

  switch (type) {
    case TabActivities.Battles:
      activityCategoryfilters.battleTypes = [
        BattleType.Battle,
        BattleType.HouseBattle,
      ];
      return activityCategoryfilters;
    case TabActivities.Challenges:
      activityCategoryfilters.battleTypes = [BattleType.Solo];
      return activityCategoryfilters;
    case TabActivities.Tournaments:
      activityCategoryfilters.battleTypes = [BattleType.Tournament];
      return activityCategoryfilters;
    case TabActivities.Leaderboards:
      activityCategoryfilters.activityTypes = [
        ActivityType.UserLeaderboardEnded,
        ActivityType.LeaderboardEnded,
      ];
      return activityCategoryfilters;
  }
};

export const renderDateTitle = (date: string, me: User, t: TFunction) => {
  if (date === "Today" || date === "Recent") {
    return t(`common_${date}`);
  } else if (me) {
    return new Date(date).toLocaleDateString(getFormatDateLanguage(me), {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  }
};
