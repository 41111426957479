import { gql } from "graphql-tag";

export const getUserMetadataGQL = gql`
  query GetUserMetadata {
    getUserMetadata {
      onboarding
      hasReceivedWelcomeCoins
      preferredGameCategories
      onboarding
      lastSeenTransactionId
      desiredStreamResolution
    }
  }
`;

export const getUserProfileGQL = gql`
  query GetUserProfile(
    $userProfileInput: UserProfileInput!
    $followingInput: FollowersInput!
    $followersInput: FollowersInput!
  ) {
    getUserProfile(userProfileInput: $userProfileInput) {
      user {
        id
        username
        avatar
        xp
      }
      friendStatus
      friendRequestId
      nbMatchesWon
      challengesCompleted
      nbFriends
      winningPercent
      allTimeRank
      lastCheckin
      followStatus
      battleStreakData {
        battleStreak
        bestBattleStreak
      }
    }
    following(followingInput: $followingInput) {
      totalCount
    }
    followers(followersInput: $followersInput) {
      totalCount
    }
  }
`;

export const getUnseenRewardTransactionsGQL = gql`
  query GetUnseenRewardTransactions {
    getUserV2 {
      unseenRewardTransactions {
        amount
        category
        createdAt
        currency
        entryId
        expiryDate
        gameId
        gameTitle
        reference
        runningBalance
        status
        transactionType
        type
        walletType
        workflow
      }
    }
  }
`;

export const loginRewardsGQL = gql`
  query {
    loginRewards {
      __typename
      ... on CoinLoginRewardProgress {
        walletType
        itemType
        amount
        status
      }
      ... on AchievementLoginRewardProgress {
        walletType
        achievementItemType: itemType
        amount
        status
      }
    }
  }
`;

export const searchUserGQL = gql`
  query Users($searchUsersInput: SearchUsersInput!) {
    users(searchUsersInput: $searchUsersInput) {
      totalCount
      items {
        id
        username
        avatar
        xp
        lastCheckin
        followStatus
      }
    }
  }
`;
